import React from "react"
import PropTypes from "prop-types"
import Client from "./client"
import Header from "./header/header"
import Footer from "./footer/footer"
import CookieConsent from "react-cookie-consent"
// import { useStaticQuery, graphql } from "gatsby"

// import Header from "./header"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="min-h-screen bg-white-100 flex flex-col">
      <Client />
      <Header />
      <main>{children}</main>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="RazvanCranganu GDPR"
      >
        This website stores data such as cookies to enable essential site
        functionality, as well as marketing, personalisation, and analytics
      </CookieConsent>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
