import React from "react"
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaGithub,
} from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="footer-wrapper">
        <div className="container">
          <div className="row">
            <div className="footer-col-1">
              <div className="footer-content">
                <p className="footer-content__title">Razvan Cranganu</p>
                <span>Cloud Architect &amp; Consultant</span>
              </div>
            </div>
            <div className="footer-col-2">
              <div className="footer-content">
                <p className="footer-content__title">Location</p>
                <span>Wirral, United Kingdom</span>
              </div>
            </div>
            <div className="footer-col-3">
              <div className="footer-content">
                <p className="footer-content__title">Get in touch</p>
                <span>hello&#64;razvancranganu.com</span>
                <span>+44 (0) 7922 747 476</span>
              </div>
            </div>
            <div className="footer-col-4">
              <div className="footer-content">
                <p className="footer-content__title">Find me on</p>
                <ul className="social-share">
                  <li>
                    <a
                      href="https://www.linkedin.com/in/razvancranganu/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="LinkedIn"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/rcranganu"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="GitHub"
                    >
                      <FaGithub />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/rcranganu/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Instagram"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/rcranganu"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Facebook"
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row footer-bottom">
            <div className="footer-copyright">
              <p>&copy; 2021 Razvan Cranganu. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
