import { Link } from "gatsby"
import React, { useState, useEffect, Fragment } from "react"
import Scrollspy from "react-scrollspy"

const Header = () => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)

  useEffect(() => {
    if (!isOverlayOpen) {
      document
        .querySelector(".trigger-popup-menu")
        .classList.remove("overlay-wrapper-open")
      document
        .querySelector(".hamburger-menu")
        .classList.remove("hamburger-menu-open")
    }
  })

  const onMenuToggleClick = () => {
    setIsOverlayOpen(prevState => !prevState)
    document
      .querySelector(".trigger-popup-menu")
      .classList.toggle("overlay-wrapper-open")
    document
      .querySelector(".hamburger-menu")
      .classList.toggle("hamburger-menu-open")
  }

  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10)
    })
  }, [])

  return (
    <Fragment>
      <header
        className={
          scroll
            ? "header header-default header-transparent scrolled  d-xl-block"
            : "header header-default header-transparent d-xl-block"
        }
      >
        <div className="header-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="header-left">
                <div className="logo">
                  <Link to="/">
                    R<span className="solid">C</span>
                  </Link>
                </div>
              </div>
              <div className="header-right">
                <div className="menu-wrapper">
                  <Scrollspy
                    className="main-menu-wrapper"
                    items={["home", "about", "contact"]}
                    currentClassName="is-current"
                    offset={-200}
                  >
                    <li>
                      <a className="menu-hover-link" href="/#home">
                        <span className="hover-item">
                          <span data-text="Home">Home</span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="menu-hover-link" href="/#about">
                        <span className="hover-item">
                          <span data-text="About">About</span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="menu-hover-link" href="/#contact">
                        <span className="hover-item">
                          <span data-text="Contact">Contact</span>
                        </span>
                      </a>
                    </li>
                  </Scrollspy>
                </div>
                <div
                  className="hamburger-menu"
                  onClick={onMenuToggleClick}
                  onKeyDown={onMenuToggleClick}
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="trigger-popup-menu">
        <div className="menu_full">
          <div className="menu_wrapper">
            <Scrollspy
              className="popup_mainmenu"
              items={["home", "about", "contact"]}
              currentClassName="is-current"
              offset={-200}
            >
              <li>
                <a
                  className="menu-hover-link"
                  href="/#home"
                  onClick={onMenuToggleClick}
                  onKeyDown={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span data-text="Home">Home</span>
                  </span>
                </a>
              </li>

              <li>
                <a
                  className="menu-hover-link"
                  href="/#about"
                  onClick={onMenuToggleClick}
                  onKeyDown={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span data-text="About">About</span>
                  </span>
                </a>
              </li>

              <li>
                <a
                  className="menu-hover-link"
                  href="/#contact"
                  onClick={onMenuToggleClick}
                  onKeyDown={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span data-text="Contact">Contact</span>
                  </span>
                </a>
              </li>
            </Scrollspy>
          </div>
          <div
            className="trigger_closer"
            onClick={onMenuToggleClick}
            onKeyDown={onMenuToggleClick}
            role="button"
            tabIndex={0}
          >
            <span className="text">Close</span>
            <span className="icon"></span>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Header
